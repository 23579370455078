import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/usersAction';

export type UsersAction = ActionType<typeof actions>;

const initialState = {
	Name: '',
};

const usersReducer = (state = initialState, action: UsersAction) => {
	switch (action.type) {
		case 'USERS_UPDATE':
			return { ...state, ...action.payload };
		default:
			return state;
	}
};

export default usersReducer;

import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/navigationActions';

export type NavigationAction = ActionType<typeof actions>;

const initialState = {
	sidebar: false,
};

const navigationReducer = (state = initialState, action: NavigationAction) => {
	switch (action.type) {
		case 'TOGGLE_SIDEBAR':
			state.sidebar = action.payload;
			return { ...state };
		default:
			return state;
	}
};

export default navigationReducer;

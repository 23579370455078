import palette from '../palette';
import typography from '../typography';

const MuiCardHeader = {
	title: {
		color: palette.secondary.main,
		fontSize: typography.h5.fontSize,
		fontWeight: 700,
	},
	subheader: {
		fontSize: typography.body1.fontSize,
	},
};

export default MuiCardHeader;

import palette from '../palette';

const MuiDataGrid = {
	root: {
		backgroundColor: palette.white,
		border: `1px solid ${palette.divider}`,
		'& .MuiDataGrid-columnsContainer': {
			borderBottom: `1px solid ${palette.divider}`,
		},
		'& .MuiDataGrid-cell': {
			borderBottom: `1px solid ${palette.divider}`,
		},
		'& .MuiDataGrid-columnSeparator': {
			color: palette.white,
		},
		'& .MuiDataGrid-colCellTitle': {
			color: palette.secondary.main,
			fontWeight: 700,
		},
	},
};

export default MuiDataGrid;

import palette from '../palette';
import { CreateCSSProperties } from '@material-ui/core/styles/withStyles';

const MuiButton = {
	contained: {
		backgroundColor: palette.primary.main,
		color: palette.primary.contrastText,
		'&:hover': {
			backgroundColor: palette.primary.dark,
		},
	},
	root: {
		textTransform: 'unset',
	} as CreateCSSProperties,
	sizeSmall: {
		height: '33px',
		// fontSize: '13px',
	},
};

export default MuiButton;

import React from 'react';

import { Dialog, DialogContent, Slide, AppBar, Toolbar, Typography, IconButton, makeStyles, DialogProps } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { appBarHeightDialog } from 'constants/layout';

const Transition = React.forwardRef(function Transition(
	props: TransitionProps & { children?: React.ReactElement<any, any> },
	ref: React.Ref<unknown>
) {
	return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
	appBar: {
		position: 'relative',
		height: appBarHeightDialog,
		boxShadow: 'none',
	},
	toolbar: {
		position: 'relative',
		minHeight: '100%',
	},
	icon: {
		position: 'absolute',
		top: '50%',
		right: '10px',
		transform: 'translateY(-50%)',
	},
	dialog: {
		fontSize: '14px',

		'& .MuiPaper-root': {
			width: '100%',
		},
	},
	dialogContent: {
		paddingTop: '24px',
		paddingBottom: '24px',
		overflow: 'auto',
		'&::-webkit-scrollbar': { width: '16px' },
		'&::-webkit-scrollbar-track': { background: 'transparent' },
		'&::-webkit-scrollbar-thumb': {
			background: '#b3b3b3',
			borderRadius: '100px',
			border: '4px solid transparent',
			backgroundClip: 'padding-box',
		},
	},
}));

interface BaseDialogProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	maxWidth?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl';
	scroll?: 'body' | 'paper';
	noHeader?: boolean;
	customMaxWidth?: string;
	simpleHeader?: boolean;
	loading?: boolean;
	className?: string;
	contentClassName?: string;
	noPadding?: boolean;
}

const BaseDialog: React.FC<BaseDialogProps & DialogProps> = ({
	open,
	onClose,
	maxWidth,
	scroll,
	children,
	title,
	noHeader,
	customMaxWidth,
	simpleHeader,
	loading,
	className,
	contentClassName,
	noPadding,
	...props
}) => {
	const classes = useStyles();

	return (
		<Dialog
			open={open}
			onClose={onClose}
			TransitionComponent={Transition}
			scroll={scroll}
			maxWidth={maxWidth || 'xs'}
			className={classNames(classes.dialog, className)}
			disableEscapeKeyDown={loading || false}
			disableBackdropClick={loading || false}
			{...props}
		>
			{!noHeader && !simpleHeader && (
				<AppBar className={classes.appBar}>
					<Toolbar className={classes.toolbar}>
						<Typography variant="h5">{title}</Typography>
						<IconButton edge="start" color="inherit" onClick={onClose} aria-label="close" className={classes.icon}>
							<CloseIcon />
						</IconButton>
					</Toolbar>
				</AppBar>
			)}

			<DialogContent className={classNames(classes.dialogContent, contentClassName, { 'p-0': noPadding })}>
				{simpleHeader && (
					<Typography variant="h4" className="mb-6">
						<strong>{title}</strong>
					</Typography>
				)}

				{children}
			</DialogContent>
		</Dialog>
	);
};

export default BaseDialog;

import { ActionType } from 'typesafe-actions';
import * as actions from 'actions/dialogActions';
import constants from '../constants';

export type DialogAction = ActionType<typeof actions>;

const initialState = {
	response: {
		status: false,
		title: '',
		content: '',
		btnText: '',
		icon: '' as JSX.Element | string,
		type: '' as 'success' | 'error',
	},
};

const dialogReducer = (state = initialState, action: DialogAction) => {
	switch (action.type) {
		case constants.dialog.update:
			return { ...state, ...action.payload };
		case constants.dialog.success:
			state.response.type = 'success';
			state.response.status = true;
			state.response.content = action.payload;
			return { ...state };
		case constants.dialog.error:
			state.response.type = 'error';
			state.response.status = true;
			state.response.content = action.payload;
			return { ...state };
		default:
			return state;
	}
};

export default dialogReducer;

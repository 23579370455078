import { drawerWidth, appBarHeight, appBarHeightDialog } from './layout';
import pdf from './pdf';

const constants = {
	pdf,
	drawerWidth,
	appBarHeight,
	appBarHeightDialog,
	dialog: {
		update: 'DIALOG_UPDATE',
		success: 'DIALOG_SUCCESS',
		error: 'DIALOG_ERROR',
	},
};
export default constants;

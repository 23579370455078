import palette from '../palette';
import typography from '../typography';

const MuiTableCell = {
	root: {
		...typography.body1,
		borderBottom: `1px solid ${palette.divider}`,
	},
	head: {
		color: palette.text.primary,
		fontSize: typography.body1.fontSize,
		fontWeight: 550,
		// backgroundColor: palette.white,
	},
	stickyHeader: {
		// backgroundColor: palette.white,
	},
};

export default MuiTableCell;

import { CircularProgress, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles(() => ({
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		height: '100%',
		flexGrow: 1,
	},
}));

interface PageLoadingProps {
	size?: number;
}

const PageLoading: React.FC<PageLoadingProps> = ({ size }) => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<CircularProgress size={size || 100} color="primary" />
		</div>
	);
};

export default PageLoading;

import constants from '../constants';

export const update = (payload: any) => {
	return {
		type: constants.dialog.update,
		payload,
	};
};

export const successDialog = (payload?: string) => {
	return {
		type: constants.dialog.success,
		payload,
	};
};

export const errorDialog = (payload?: string) => {
	return {
		type: constants.dialog.error,
		payload,
	};
};

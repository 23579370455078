import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import PageLoading from './components/layout/PageLoading';

const Login = lazy(() => import('./pages/Login'));
const PrivateRoutes = lazy(() => import('./routes/PrivateRoutes'));

const Routes = () => (
	<Router>
		<Suspense
			fallback={
				<div className="min-h-screen flex flex-col">
					<PageLoading />
				</div>
			}
		>
			<Switch>
				<Route exact path="/login" component={Login} />
				<Route path="/" component={PrivateRoutes} />
			</Switch>
		</Suspense>
	</Router>
);

export default Routes;

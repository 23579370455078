import { combineReducers } from 'redux';
import navigation from './navigationReducer';
import claims from './claimsReducer';
import dialog from './dialogReducer';
import users from './usersReducer';
import auth from './authReducer';

const combinedReducer = combineReducers({
	navigation,
	claims,
	dialog,
	users,
	auth,
});

export const rootReducer = (state: any, action: any) => {
	return combinedReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

// export default rootReducer;

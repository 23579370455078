import { colors } from '@material-ui/core';

const white = '#FFFFFF';
const black = '#000000';

const theme = {
	black,
	white,
	primary: {
		contrastText: white,
		dark: '#005f03',
		main: '#008905',
		light: '#33a037',
	},
	secondary: {
		contrastText: white,
		dark: '#353535',
		main: '#4d4d4d',
		light: '#707070',
	},
	success: {
		contrastText: white,
		dark: colors.green[900],
		main: colors.green[600],
		light: colors.green[400],
	},
	info: {
		contrastText: white,
		dark: colors.blue[900],
		main: colors.blue[600],
		light: colors.blue[400],
	},
	warning: {
		contrastText: white,
		dark: colors.orange[900],
		main: colors.orange[600],
		light: colors.orange[400],
	},
	error: {
		contrastText: white,
		dark: colors.red[900],
		main: colors.red[600],
		light: colors.red[400],
	},
	text: {
		primary: '#4d4d4d',
		secondary: '#707070',
		link: colors.blue[600],
		white: '#fff',
	},
	background: {
		default: '#F4F6F8',
		paper: white,
	},
	icon: '#4d4d4d',
	divider: colors.grey[300],
};

export default theme;

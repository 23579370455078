// import palette from '../palette';

const MuiTextField = {
	root: {
		'& .MuiInputBase-multiline': {
			lineHeight: '20px',
		},
	},
};

export default MuiTextField;

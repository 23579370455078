import React from 'react';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import './App.css';
import Routes from './routes';
import theme from './theme';
import ResponseDialog from 'components/dialogs/ResponseDialog';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './reducers';
import { update } from 'actions/dialogActions';

const useStyles = makeStyles((theme) => ({
	root: {
		minHeight: '100vh',
	},
}));

function App() {
	const classes = useStyles();
	const { response } = useSelector((state: RootState) => state.dialog);
	const dispatch = useDispatch();

	return (
		<div className={classes.root}>
			<ResponseDialog
				open={response.status}
				onClose={() => dispatch(update({ response: { ...response, status: false } }))}
				type={response.type}
				content={response.content}
			/>

			<ThemeProvider theme={theme}>
				<Routes />
			</ThemeProvider>
		</div>
	);
}

export default App;

import { ActionType } from 'typesafe-actions';
import * as actions from 'actions/claimsActions';

export type ClaimsAction = ActionType<typeof actions>;

export type FormsSubmitted =
	| 'application'
	| 'section1'
	| 'section2'
	| 'section3'
	| 'section4'
	| 'section5'
	| 'section6';

const initialState: { [key: string]: any } = {
	sectionLocked: true,
	formsSubmitted: [] as FormsSubmitted[],
	form: {
		ECTransactionID: '',
		ECHospitalInfoID: '',
		PatientDisposition: '',
		DischargeDateTime: '',
		AdmissionDateTime: '',
		CompletionPercentage: 0,
		DiagnosisList: [],
		HealthcareList: [],
		PertinentSignSymptomsList: [],
		PEGeneralSurveyList: [],
		HEENT: [],
		CVS: [],
		ChestLungs: [],
		Abdomen: [],
		GU: [],
		SkinExtremities: [],
		NeuroExam: [],
		McpPackage: ['', '', '', ''],
		SpecialConsiderationList: [],
	},
	cities: [],
};

export type InitialState = typeof initialState;

const claimsReducer = (state = initialState, action: ClaimsAction) => {
	switch (action.type) {
		case 'UPDATE':
			Object.keys(action.payload).forEach((property) => {
				state[property] = action.payload[property];
				// if (action.payload[property]) {

				// }
			});
			return { ...state };
		case 'UPDATE_FORM':
			Object.keys(action.payload).forEach((property) => {
				state.form[property] = action.payload[property];
			});

			return { ...state };
		case 'ADD_FORMS_SUBMITTED':
			if (!state.formsSubmitted.includes(action.payload)) {
				state.formsSubmitted.push(action.payload);
			}
			return { ...state };
		default:
			return state;
	}
};

export default claimsReducer;

import palette from '../palette';
import typography from '../typography';

const MuiListItemText = {
	primary: {
		color: palette.text.primary,
		fontSize: typography.body1.fontSize,
	},
	secondary: {
		color: palette.text.secondary,
		fontWeight: 400,
	},
};

export default MuiListItemText;

import { ActionType } from 'typesafe-actions';
import * as actions from '../actions/authActions';
import localforage from 'localforage';

export type AuthAction = ActionType<typeof actions>;

const initialState = {
	loggedIn: false,
	token: '',
	user: {
		UserCompletename: '',
		UserAccess: '',
		UsercccountID: '',
		Usertype: '',
		ECClaimTypeID: '',
		hasRegularClaims: false,
		hasAnimalBiteClaims: false,
		hasPCRTestClaims: false,
		isSCSFApprover: false,
		CreatedBy: '',
		CreatedDateTime: '',
		Keycode: '',
		LastLoginDateTime: '',
		PassPhrase: '',
		UpdatedBy: '',
		UpdatedDateTime: '',
	},
};

const authReducer = (state = initialState, action: AuthAction) => {
	switch (action.type) {
		case 'AUTH_UPDATE':
			return { ...state, ...action.payload };
		case 'AUTH_LOGOUT':
			localStorage.clear();
			localforage.clear();
			state.loggedIn = false;
			return { ...state };
		default:
			return state;
	}
};

export default authReducer;

import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import BaseDialog from './BaseDialog';

const centerStyles = {
	display: 'flex',
	justifyContent: 'center',
};

const useStyles = makeStyles((theme) => ({
	titleError: {
		backgroundColor: theme.palette.error.main,
		color: 'white',
		'&:hover, &:focus': {
			background: theme.palette.error.dark,
		},
	},
	titleSuccess: {
		backgroundColor: theme.palette.success.main,
		color: 'white',
		'&:hover, &:focus': {
			background: theme.palette.success.dark,
		},
	},
	titlePrimary: {
		backgroundColor: theme.palette.primary.main,
		color: 'white',
		'&:hover, &:focus': {
			background: theme.palette.primary.dark,
		},
	},
	title: {
		...centerStyles,
		lineHeight: 1.5,
		fontWeight: 'bold',
		marginTop: '10px',
		fontSize: 30,
		textAlign: 'center',
	},
	iconSuccess: {
		color: theme.palette.success.main,
		fontSize: '100px',
	},
	iconWarning: {
		color: theme.palette.warning.main,
		fontSize: '100px',
	},
	iconError: {
		color: theme.palette.error.main,
		fontSize: '100px',
	},
	msg: {
		...centerStyles,
		fontSize: 15,
		textAlign: 'center',
		marginTop: theme.spacing(1),
	},
	footer: {
		...centerStyles,
		marginBottom: 20,
		marginTop: theme.spacing(0.5),
	},
	fontBold: {
		fontWeight: 'bold',
	},
	mt2: {
		marginTop: theme.spacing(2),
	},
}));

interface ResponseDialogProps {
	open: boolean;
	onClose: () => void;
	icon?: JSX.Element;
	title?: string;
	content?: string;
	type?: 'success' | 'error' | 'warning';
}

const ResponseDialog: React.FC<ResponseDialogProps> = ({ type, title, content, icon, ...props }) => {
	const classes = useStyles();

	return (
		<BaseDialog {...props} maxWidth="xs" noHeader data-dialog-cy="response">
			<div className={classes.title}>
				{type === 'success' ? (
					<CheckCircleIcon className={classes.iconSuccess} />
				) : type === 'warning' ? (
					<ErrorOutlineIcon className={classes.iconWarning} />
				) : (
					<CancelIcon className={classes.iconError} />
				)}
			</div>
			<div data-cy="title" className={classes.title}>
				{title || (type === 'success' ? 'Success' : type === 'warning' ? 'Warning' : 'Error')}
			</div>
			<div data-cy="message" className={classes.msg}>
				<div>{content || (type === 'success' ? 'Success' : type === 'warning' ? 'Warning' : 'Server Error. Please refresh the page')}</div>
			</div>
			<br />
			<br />
			{/* <div className="mt-5 flex justify-center">
				<BaseButton onClick={() => props.onClose()} size="large" shape="regular" width="60px" customColor={type}>
					OK
				</BaseButton>
			</div> */}
		</BaseDialog>
	);
};

export default ResponseDialog;
